import css from './NewUserFormView.module.css';
import React, { useState, useEffect} from 'react';


import Spinner from './OrderSteps/Spinner/Spinner';
import RotateLoader from 'react-spinners/RotateLoader';
import Initial from './OrderSteps/Initial/Initial';
import CustomerInfo from './OrderSteps/CustomerInfo/CustomerInfo';
import MonitoringAddress from './OrderSteps/MonitoringAddress/MonitoringAddress';
import CallListMessage from './OrderSteps/CallListMessage/CallListMessage'
import CallList from './OrderSteps/CallList/CallList';
import TermsConfirmation from './OrderSteps/TermsConfirmation/TermsConfirmation';
import PlainPage from './OrderSteps/PlainPage/PlainPage';
import SubmissionError from './OrderSteps/SubmissionError/SubmissionError';
import TextInputQuestion from './OrderSteps/TextInputQuestion/TextInputQuestion';
//email, pdf
import emailjs from 'emailjs-com';
import {pdf} from '@react-pdf/renderer';
import ReactDOM from 'react-dom';
import { PDFViewer } from '@react-pdf/renderer';
import PDFDocumentNewAccount from './PDFDocumentNewAccount/PDFDocumentNewAccount'

const NewUserFormView = ({
    }) => {
 
        const [step, setStep]=useState(1)
        const [emailSent, setEmailSent] = useState(false);
        const [showSpinner, setShowSpinner] = useState(false);
        const [previewUrls, setPreviewUrls] = useState([]);
        const [customerType, setCustomerType] = useState('homeowner');
        const [pdfID, setPdfID] = useState(generateShortId() )
        const [callList, setCallList] = useState([]);
        const [monitoringAddress, setMonitoringAddress] = useState({
            id:'',
            address: '',
            unit: '',
            city: '',
            province: 'AB',
            postal: '',
        });
        const [customerInfo, setCustomerInfo] = useState({
            business: '',
            fname: '',
            lname: '',
            phone: '',
            email: '',
            address: '',
            unit: '',
            city: '',
            province: '',
            postal: '',
            country: ''
        });
        
    
        

        function generateShortId() {
            return Math.floor(100000 + Math.random() * 900000);
        }
        useEffect(() => {
            if (step === 11 && !emailSent) {
                (async () => {
                    const emailSuccess = await sendEmail();
                    if (emailSuccess) {
                        setEmailSent(true);
                        setTimeout(() => {
                            setStep(13)//success)
                        }, 4000);
                    } else {
                        setEmailSent(false);
                        setTimeout(() => {
                            setStep(12)//failed
                        }, 4000);
                        
                    }
                })();
            }
        }, [step, emailSent]);
    
        const nextStep=()=>setStep(step+1)
        const prevStep=()=>setStep(step-1) 
        const sendEmail = async () => {
            const serviceId = 'service_52qj8o7';
            const templateId = 'template_r9d1vui';
            const userId = 'Pgvr9-YrZHu7sbMO4';
            const ccEmail = 'tech@securepol.com';
        
            try {
                const blob = await pdf(<PDFDocumentNewAccount 
                    pdfID={pdfID}
                    monitoringAddress={monitoringAddress} 
                    customerInfo={customerInfo} 
                    callList={callList}
                />).toBlob();
                const base64data = await convertBlobToBase64(blob);
                if (!base64data) {
                    console.error('Failed to convert PDF blob to base64.');
                    return false;
                }
                const response = await emailjs.send(
                    serviceId,
                    templateId,
                    {
                        pdfID: pdfID,
                        pdfFile: base64data, 
                        ...customerInfo,
                        ccEmail: ccEmail,
                    },
                    userId
                );
                if (response.status === 200) {
                    return true;
                } else {
                    return false;
                }
            } catch (error) {
                console.error('Email error:', error);
                return false;
            }
        };
        
        
          
        
          const convertBlobToBase64 = (blob) => {
            return new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.readAsDataURL(blob);
              reader.onloadend = () => resolve(reader.result.split('base64,')[1]);
              reader.onerror = (error) => reject(error);
            });
          };
        
    
    const renderSteep = () =>{
        switch(step){
            case 0:
                window.open('./', '_self');
            break;
            case 1:
                return(
                    <Initial
                        step={step}
                        setStep={setStep}
                        nextStep={nextStep}
                        prevStep={prevStep}
                    />
                );
            case 2:
            return(
                <TextInputQuestion
                    step={step}
                    setStep={setStep}
                    nextStep={nextStep}
                    prevStep={prevStep}
                    info={monitoringAddress}
                    setInfo={setMonitoringAddress}
                    infoKey ='id'
                    header='Enter Estimate ID'
                    paragraph="Enter the estimate ID number from estimate form, or enter 0 if you don't have one."
                    placeHolder=''
                    positiveCheckBox='Yes'
                    negativeCheckBox='No'
                    backButtonDir={1}
                    nextButtonDir={step+1}
                    
                />
                );
            case 3:
            return(
                <Spinner 
                    step={step} 
                    setStep={setStep} 
                    message={'Preparing forms...'} 
                    spinnerTime={4000}
                />
                    
            );
            
            case 4:
                return(
                <MonitoringAddress
                    step={step}
                    setStep={setStep}
                    nextStep={nextStep}
                    prevStep={prevStep}
                    monitoringAddress={monitoringAddress}
                    setMonitoringAddress={setMonitoringAddress}
                />
                );
            case 5:
                return(
                <CustomerInfo
                    step={step}
                    setStep={setStep}
                    nextStep={nextStep}
                    prevStep={prevStep}
                    
                    monitoringAddress={monitoringAddress}
                    setMonitoringAddress={setMonitoringAddress}
                    customerInfo={customerInfo}
                    setCustomerInfo={setCustomerInfo}
                    customerType={customerType}
                />
                );
            case 6:
            return(
                <Spinner 
                    step={step} 
                    setStep={setStep} 
                    message={'Preparing call list...'} 
                    spinnerTime={4000}
                />
                    
            );
            case 7:
                return(
                <CallListMessage
                    step={step}
                    setStep={setStep}
                    nextStep={nextStep}
                    prevStep={prevStep}
                    setCallList={setCallList}
                />
            );
            case 8:
                return(
                <CallList
                    step={step}
                    setStep={setStep}
                    nextStep={nextStep}
                    prevStep={prevStep}
                    
                    monitoringAddress={monitoringAddress}
                    setMonitoringAddress={setMonitoringAddress}
                    customerInfo={customerInfo}
                    setCustomerInfo={setCustomerInfo}
                    customerType={customerType}
                    callList={callList}
                    setCallList={setCallList}
                />
            );
            case 9:
                return(
                <Spinner 
                    step={step} 
                    setStep={setStep} 
                    message={'Finalizing application...'} 
                    spinnerTime={6000}
                />   
            );
            case 10:
                return(
                <TermsConfirmation
                    step={step}
                    setStep={setStep}
                    nextStep={nextStep}
                    prevStep={prevStep}
                    monitoringAddress={monitoringAddress}
                    setMonitoringAddress={setMonitoringAddress}
                    customerInfo={customerInfo}
                    setCustomerInfo={setCustomerInfo}
                    customerType={customerType}
                    callList={callList}
                    setCallList={setCallList}
                />
            );
            case 11:
                    return(
                        <>
                        <div className={css.procceedBox}>
                          <div className={css.messageBox}>
                            <div className={css.spinnerContainer}>
                              <RotateLoader  size={12} color="#274E65"/>
                                <div className={css.message}>
                                  <p>Submiting applicaiton...</p>
                                </div>
                            </div>
                          </div>
                        </div>
                        <div className={css.buttonBox}>
                        </div>
                      </>  
                    );
                
            case 12:
                return(
                <SubmissionError
                    step={step}
                    setStep={setStep}
                    nextStep={nextStep}
                    prevStep={prevStep}
                   
                    monitoringAddress={monitoringAddress}
                    setMonitoringAddress={setMonitoringAddress}
                    customerInfo={customerInfo}
                    setCustomerInfo={setCustomerInfo}
                    customerType={customerType}
                    callList={callList}
                    setCallList={setCallList}
                />
                );
            case 13:
                return(
                    <PlainPage
                    step={step}
                    setStep={setStep}
                    nextStep={nextStep}
                    prevStep={prevStep}
                    header = "Submission successful"
                    paragraph = "Your application is now in the queue for processing. Our team at Securepol will reach out to you shortly."
                    nextButtonDir={0}
                    nextButtonLabel="Close"
                    isBackButton={false}
                    />
            );
        }
    }
    return( 
        <div className={css.sectionWrapperFull}>
            <div className={css.body}>
                {renderSteep()}
            </div>
        </div>
    )
}
export default NewUserFormView;

  // <PDFViewer style={{ height: '100vh', width: '100%' }}>
                    //     <PDFDocumentSale 
                    //         monitoringAddress={monitoringAddress} 
                    //         customerInfo={customerInfo} 
                    //         callList={callList}
                    //         basket={basket} 
                    //         project={project} 
                    //         upfront={upfront} 
                    //         monthly={monthly} 
                    //         tax={tax} 
                    //         contractDuration={contractDuration} 
                            
                    //     />
                    // </PDFViewer>
                