import css from './MonitoringAddress.module.css';
import React, { useEffect, useState } from 'react';
import {formatAddress, validateAddress} from '../../../AppCommonComponents/FormInput/Address';
import {formatPostal, validatePostal} from '../../../AppCommonComponents/FormInput/Postal';
import {formatName, validateName} from '../../../AppCommonComponents/FormInput/Name';
import { FaCheck } from "react-icons/fa6";
import { FaStarOfLife } from "react-icons/fa6";



const MonitoringAddress = ({ step, setStep, nextStep, prevStep, contractDuration, monitoringAddress, setMonitoringAddress }) => {

    const [isChecked, setIsChecked] = useState(false);
    const [isValid, setIsValid] = useState({
        address:false,
        unit:false,
        city:false,
        province:true,
        postal:false
    })
    const[isFormVaolid, setIsFormValid] = useState(false)
   
    const validateData=()=>{
        setIsValid({
            address: validateAddress(monitoringAddress.address),
            unit: true,
            city: validateName(monitoringAddress.city),
            province: monitoringAddress.province === 'AB',
            postal: validatePostal(monitoringAddress.postal)
        })
        const valid = 
        validateAddress(monitoringAddress.address) && 
        validateName(monitoringAddress.city) &&
        monitoringAddress.province === 'AB' &&
        validatePostal(monitoringAddress.postal)
        setIsFormValid(valid)
    }
                                              
    
    useEffect(()=>{
        validateData()
    },[monitoringAddress])

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };
    
    const handleAddressChange = (e) => {
        let { name, value } = e.target;
        if(name === 'address' || name === 'unit'){
            value = formatAddress(value);
        }
        if(name === 'city'){
            value = formatName(value);
        }
        if(name === 'postal'){
            value = formatPostal(value);
        }
        setMonitoringAddress({
            ...monitoringAddress,
            [name]: value,
        });
        validateData();
    }
    

    return (
        <div className={css.proceedBox}>
            <div className={css.messageBox}>
                <h2>Monitoring Address</h2>
                <div className={css.descriptionBox}>
                    <div className={css.description}>
                    <p className={css.messageInstruction}>Please enter the full address where the service will be provided.</p>
                    </div>
                </div>
                <div className={css.itemBox}>
                    <div className={css.itemWrapper}>
                        <div className={css.inputContainer}>
                            <label className={css.inputLabel} htmlFor='address'>Address*</label>
                            <input
                                className={css.inputField}
                                id='address'
                                name='address'
                                type='text'
                                required='required'
                                value={monitoringAddress.address}
                                onChange={handleAddressChange}
                            />{isValid.address?<FaCheck className={css.valid}/>:<FaStarOfLife className={css.inValid}/>}
                        </div>
                        <div className={css.inputContainer}>
                            <label className={css.inputLabel} htmlFor='unit'>Unit</label>
                            <input
                                className={css.inputField}
                                id='unit'
                                name='unit'
                                type='text'
                                value={monitoringAddress.unit}
                                onChange={handleAddressChange}
                            />{true?<FaCheck className={css.validoff}/>:<FaStarOfLife className={css.inValid}/>}
                        </div>
                        <div className={css.inputContainer}>
                            <label className={css.inputLabel} htmlFor='city'>City*</label>
                            <input
                                className={css.inputField}
                                id='city'
                                name='city'
                                type='text'
                                required='required'
                                value={monitoringAddress.city}
                                onChange={handleAddressChange}
                            />{isValid.city?<FaCheck className={css.valid}/>:<FaStarOfLife className={css.inValid}/>}
                        </div>
                        <div className={css.inputContainer}>
                            <label className={css.inputLabel} htmlFor='province'>Province*</label>
                            <input
                                className={css.inputField}
                                id='province'
                                name='province'
                                type='text'
                                required='required'
                                readOnly='readonly'
                                value={'AB'}
                                onChange={handleAddressChange}
                            />{isValid.province?<FaCheck className={css.validoff}/>:<FaStarOfLife className={css.inValid}/>}
                        </div>
                        <div className={css.inputContainer}>
                            <label className={css.inputLabel} htmlFor='postal'>Postal Code*</label>
                            <input
                                className={css.inputField}
                                id='postal'
                                name='postal'
                                type='text'
                                required='required'
                                value={monitoringAddress.postal}
                                onChange={handleAddressChange}
                                maxLength={7} // Maximum length to accommodate format 'A1A 1A1'
                            />{isValid.postal?<FaCheck className={css.valid}/>:<FaStarOfLife className={css.inValid}/>}
                        </div>
                    </div>
                </div>
            </div>
            <div className={css.buttonBox}>
                <div className={css.checkboxBox}>
                    <div className={css.checkbox} onClick={handleCheckboxChange}>
                    </div>
                    <div className={css.term}>
                        <label></label>
                    </div>
                </div>
                <button
                    className={css.buttonActive}
                    onClick={() => setStep(2)}
                >
                    Back
                </button>
                <button
                    className={isFormVaolid ? css.buttonActive : css.buttonInActive}
                    onClick={nextStep}
                    disabled={!isFormVaolid}
                >
                    Next
                </button>
            </div>
        </div>
    );
};

export default MonitoringAddress;
